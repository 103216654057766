
import api from "@/api";
import { AxiosRequestConfig } from "axios";
import { Toast } from 'vant';
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";

interface lineInfo {
  busMoney: string;
  busStaName: string;
  endSta: string;
  endTime: string;
  firstTime: string;
  otherLineId: string;
  startSta: string;
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      buses: [],
      lineInfo: {} as lineInfo,
      stations: [],
      cityId: route.query.cityId,
      staName: route.query.staName,
      lineName: route.query.lineName,
      lineCode: route.query.lineCode,
      active: -1,
    });
    const goBack = () => {
      router.go(-1);
    };
    api.bus.rtbus({
      cityid: state.cityId,
      busStaname: state.staName,
      busLinenum: state.lineName,
      busLinestrid: state.lineCode,
    }).then((res: AxiosRequestConfig["data"]) => {
      if (res.code === 0) {
        state.lineInfo = res.data.lineInfo;
        state.stations = res.data.stations;
        state.buses = res.data.buses;
      }
    });
    const handleActive = (i: number) => {
      console.log(i);
      state.active = i;
    };
    const handleReversing = () => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      api.bus.rtbus({
        cityid: state.cityId,
        busStaname: state.staName,
        busLinenum: state.lineName,
        busLinestrid: state.lineInfo.otherLineId,
      }).then((res: AxiosRequestConfig["data"]) => {
        if (res.code === 0) {
          state.lineInfo = res.data.lineInfo;
          state.stations = res.data.stations;
          state.buses = res.data.buses;
        }
      });
    };
    return {
      handleReversing,
      handleActive,
      goBack,
      ...toRefs(state),
    };
  },
});
