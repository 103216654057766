<template>
  <div class="content">
    <van-sticky>
      <van-nav-bar
        :title="staName + '路'"
        left-text="返回"
        left-arrow
        @click-left="goBack"
      />
    </van-sticky>
    <div class="bus-info">
      <p class="direction">
        <span class="direction-info" >
          方向：{{ stations[stations.length - 1] }}
        </span>
        <span class="time">
          <span class="start">首</span>
          {{ lineInfo.firstTime }}
          <span class="end">末</span>
          {{ lineInfo.endTime }}
        </span>
      </p>
      <p class="from-to">
        {{ stations[0] }}
        →
        {{ stations[stations.length - 1] }}
      </p>
      <p class="detail">
        <span class="price">票价：{{ lineInfo.busMoney }}</span>
        <span class="buses">{{ buses.length }}辆车正在行驶</span>
      </p>
    </div>
    <div class="line">
      <div class="station" v-for="(item, index) in stations" :key="item">
        <!-- 起点 -->
        <div
          :class="['first', { active: index === active }]"
          v-if="index === 0"
          @click="handleActive(index)"
        >
          <!-- 距离 -->
          <div class="dis" v-for="bus in buses" :key="bus.id">
            <p v-if="index == bus.disSta">已到站</p>
          </div>
          <!-- 在行驶的bus -->
          <div class="bus" v-for="bus in buses" :key="bus.id">
            <i v-if="index == bus.disSta" class="iconfont icon-weibiaoti507" ></i>
          </div>
          <div class="station-line"></div>
          <p class="since">起</p>
          <p class="index">{{ index }}</p>
          <p class="name">{{ item }}</p>
        </div>
        <!-- 终点 -->
        <div
          :class="['end', { active: index === active }]"
          v-else-if="index === stations.length - 1"
          @click="handleActive(index)"
        >
          <!-- 距离 -->
          <div class="dis" v-for="bus in buses" :key="bus.id">
            <p v-if="index == bus.disSta && bus.dis == 0">已到站</p>
            <p class="moving" v-if="index == bus.disSta && bus.dis != 0">
              距离下站
              <br>
              {{bus.dis}}米
            </p>
          </div>
          <!-- 在行驶的bus -->
          <div class="bus" v-for="bus in buses" :key="bus.id">
            <i v-if="index == bus.disSta && bus.dis == 0" class="iconfont icon-weibiaoti507" ></i>
            <i v-if="index == bus.disSta && bus.dis != 0" class="iconfont icon-weibiaoti507 moving" ></i>
          </div>
          <p class="final">终</p>
          <p class="index">{{ index }}</p>
          <p class="name">{{ item }}</p>
        </div>
        <!-- 其他站点 -->
        <div
          :class="['other', { active: index === active }]"
          v-else
          @click="handleActive(index)"
        >
          <!-- 距离 -->
          <div class="dis" v-for="bus in buses" :key="bus.id">
            <p v-if="index == bus.disSta && bus.dis == 0">已到站</p>
            <p class="moving" v-if="index == bus.disSta && bus.dis != 0">
              距离下站
              <br>
              {{bus.dis}}米
            </p>
          </div>
          <!-- 在行驶的bus -->
          <div class="bus" v-for="bus in buses" :key="bus.id">
            <i v-if="index == bus.disSta && bus.dis == 0" class="iconfont icon-weibiaoti507" ></i>
            <i v-if="index == bus.disSta && bus.dis != 0" class="iconfont icon-weibiaoti507 moving" ></i>
          </div>
          <!-- 线 -->
          <div class="station-line"></div>
          <p class="arrow">→</p>
          <!-- 站名 -->
          <p class="index">{{ index }}</p>
          <p class="name">{{ item }}</p>
        </div>
      </div>
    </div>
    <div class="change">
      <van-button
        type="primary"
        icon="exchange"
        plain
        size="normal"
        round
        @click="handleReversing"
        >换向
      </van-button>
    </div>
  </div>
  <div class="tips small">部分数据来源：高德实时公交</div>
</template>

<script lang="ts">
import api from "@/api";
import { AxiosRequestConfig } from "axios";
import { Toast } from 'vant';
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";

interface lineInfo {
  busMoney: string;
  busStaName: string;
  endSta: string;
  endTime: string;
  firstTime: string;
  otherLineId: string;
  startSta: string;
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      buses: [],
      lineInfo: {} as lineInfo,
      stations: [],
      cityId: route.query.cityId,
      staName: route.query.staName,
      lineName: route.query.lineName,
      lineCode: route.query.lineCode,
      active: -1,
    });
    const goBack = () => {
      router.go(-1);
    };
    api.bus.rtbus({
      cityid: state.cityId,
      busStaname: state.staName,
      busLinenum: state.lineName,
      busLinestrid: state.lineCode,
    }).then((res: AxiosRequestConfig["data"]) => {
      if (res.code === 0) {
        state.lineInfo = res.data.lineInfo;
        state.stations = res.data.stations;
        state.buses = res.data.buses;
      }
    });
    const handleActive = (i: number) => {
      console.log(i);
      state.active = i;
    };
    const handleReversing = () => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      api.bus.rtbus({
        cityid: state.cityId,
        busStaname: state.staName,
        busLinenum: state.lineName,
        busLinestrid: state.lineInfo.otherLineId,
      }).then((res: AxiosRequestConfig["data"]) => {
        if (res.code === 0) {
          state.lineInfo = res.data.lineInfo;
          state.stations = res.data.stations;
          state.buses = res.data.buses;
        }
      });
    };
    return {
      handleReversing,
      handleActive,
      goBack,
      ...toRefs(state),
    };
  },
});
</script>


<style lang="less" scoped>
.content {
  position: relative;
  height: 100%;
}
.bus-info {
  padding: 16px;
  font-size: 24px;
  background-color: #fff;
  .direction {
    margin: 0;
  }
  .direction-info {
    font-size: 32px;
  }
  .from-to {
    font-size: 28px;
  }
  .time {
    margin-left: 32px;
    color: #333;
    .start {
      padding: 0 4px;
      color: #fff;
      background-color: #07c160;
    }
    .end {
      padding: 0 4px;
      color: #fff;
      background-color: #e92f44;
    }
  }
  .detail {
    padding: 16px;
    border-top: 1px solid #ddd;
    // border-bottom: 1px solid #ddd;
    .price {
      margin-right: 24px;
    }
  }
}

.line {
  margin-top: 16px;
  padding: 32px 16px;
  width: 100%;
  display: flex;
  overflow: auto;
  font-size: 26px;
  color: #333;
  box-sizing: border-box;
  // flex-shrink: 0;
  // flex-basis: auto;
  p {
    margin: 0;
  }
  .station {
    flex: 0 0 120px;
    .dis {
      position: absolute;
      top: 10px;
      font-size: 24px;
      color: #ff6900;
      .moving {
        text-align: center;
        margin-top: -16px;
        margin-left: -76px;
      }
    }
    .bus {
      position: absolute;
      top: 40px;
      i {
        font-size: 60px;
        color: #1989fa;
      }
      .moving {
        margin-left: -60px;
      }
    }
    .first,
    .other,
    .end {
      padding: 120px 0 0 0;
      position: relative;
      // overflow: hidden;
      &.active {
        // font-weight: bold;
        .index,
        .name {
          color: #ff6900;
        }
      }
    }
    .first,
    .other {
      .station-line {
        position: absolute;
        top: 88px;
        height: 20px;
        width: 100%;
        background: #07c160;
      }
    }
    .since {
      position: absolute;
      top: 73px;
      // margin-left: 16px;
      width: fit-content;
      padding: 0 4px;
      color: #fff;
      background-color: #07c160;
      z-index: 3;
    }
    .final {
      position: absolute;
      top: 73px;
      z-index: 3;
      // margin-left: 16px;
      width: fit-content;
      padding: 0 4px;
      color: #fff;
      background-color: #e92f44;
    }
    .arrow {
      position: absolute;
      top: 73px;
      left: -8px;
      margin-left: 16px;
      font-size: 36px;
      color: #fff;
      font-weight: bold;
      z-index: 3;
    }
    .index {
      margin-left: 8px;
      font-size: 28px;
      font-weight: bold;
      // margin-top: 88px;
    }
    .name {
      margin-left: 8px;
      width: 30px;
      font-size: 32px;
    }
  }
}

.change {
  position: absolute;
  bottom: 200px;
  left: 42%;
  text-align: center;
}

.tips {
  padding: 8px;
  background: @base-background-color;
  text-align: center;
  font-size: 24px;
  color: rgb(117, 117, 117);
  &.small {
    margin-top: 32px;
    font-size: 24px;
  }
}
</style>
